import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/react";
import Container from "components/Container";
import Seo from "../components/SEO";
import Layout from "../components/Layout";
import Link from "../components/Link";
import { bpMaxSM, bpMaxMD } from "../lib/breakpoints";

const Blog = ({
  data: { site, allMdx },
  pageContext: { pagination, categories },
}) => {
  const { page, nextPagePath, previousPagePath } = pagination;

  const posts = page
    .map((id) => allMdx.edges.find((edge) => edge.node.id === id))
    .filter((post) => post !== undefined);

  return (
    <Layout site={site}>
      <Seo />
      <Container>
        <h1>All blog posts</h1>
        {posts.map(({ node: post }) => (
          <div
            key={post.id}
            css={css`
              margin-bottom: 5px;
            `}
          >
            <div>
              <Link
                to={`/${post.frontmatter.slug}`}
                aria-label={`View ${post.frontmatter.title}`}
              >
                {post.frontmatter.listTitle || post.frontmatter.title}
              </Link>
              <span
                css={css`
                  margin-left: 8px;
                  font-size: 0.6rem;
                `}
              >
                {post.frontmatter.date}
              </span>
            </div>
          </div>
        ))}
      </Container>
      {1 === 0 && (
        <Container noVerticalPadding>
          {posts.map(({ node: post }) => (
            <div
              key={post.id}
              css={css`
                :not(:first-of-type) {
                  margin-top: 20px;
                  ${bpMaxMD} {
                    margin-top: 10px;
                  }
                  ${bpMaxSM} {
                    margin-top: 10px;
                  }
                }
                :first-of-type {
                  margin-top: 20px;
                  ${bpMaxSM} {
                    margin-top: 20px;
                  }
                }
                .gatsby-image-wrapper {
                }
                ${bpMaxSM} {
                  padding: 20px;
                }
                display: flex;
                flex-direction: column;
              `}
            >
              {post.frontmatter.banner && (
                <div
                  css={css`
                    padding: 60px 60px 40px 60px;
                    ${bpMaxSM} {
                      padding: 20px;
                    }
                  `}
                >
                  <Link
                    aria-label={`View ${post.frontmatter.title} article`}
                    to={`/${post.fields.slug}`}
                  >
                    <Img
                      sizes={post.frontmatter.banner.childImageSharp.fluid}
                    />
                  </Link>
                </div>
              )}
              <h2
                css={css`
                  margin-top: 10px;
                  margin-bottom: 10px;
                `}
              >
                <Link
                  aria-label={`View ${post.frontmatter.title} article`}
                  to={`/${post.fields.slug}`}
                >
                  {post.frontmatter.title}
                </Link>
              </h2>
              <p
                css={css`
                  margin-top: 10px;
                `}
              >
                {post.excerpt}

                <Link
                  css={css`
                    margin-left: 8px;
                  `}
                  to={`/${post.fields.slug}`}
                  aria-label={`view "${post.frontmatter.title}" article`}
                >
                  Read Article →
                </Link>
              </p>
            </div>
          ))}
          <div css={css({ marginTop: "30px" })}>
            {nextPagePath && (
              <Link to={nextPagePath} aria-label="View next page">
                Next Page →
              </Link>
            )}
            {previousPagePath && (
              <Link to={previousPagePath} aria-label="View previous page">
                ← Previous Page
              </Link>
            )}
          </div>
          <hr
            css={css`
              margin: 50px 0;
            `}
          />
        </Container>
      )}
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query {
    site {
      ...site
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { isPost: { eq: true } }
        frontmatter: { published: { ne: false }, static: { ne: true } }
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          id
          fields {
            title
            slug
            date
          }
          frontmatter {
            title
            listTitle
            date(formatString: "MMMM DD, YYYY")
            #            banner {
            #              childImageSharp {
            #                fluid(maxWidth: 600) {
            #                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
            #                }
            #              }
            #            }
            slug
            keywords
          }
        }
      }
    }
  }
`;
